<template>
  <div class="recommendation-box">
    <h2 class="title">Recommendations</h2>
    <p class="sub-text">So that you don't have to just take my word for my skills and experience.
      Here are some recommendations that I've received. Hopefully they can be helpful
    in shaping your opinion about me.</p>
    <div>
      <carousel :perPage="1" class="slider-wrapper">
        <Slide v-for="recommendation in recommendations" v-bind:key="recommendation.id">
          <div class="recommendation-box">
            <div class="header">
              <img :src="recommendation.imageLink" :alt="recommendation.imageLink">
              <h2>{{recommendation.company}}</h2>
              <h3><i>({{recommendation.position}})</i></h3>
            </div>
            <div class="main">
              <p>{{recommendation.description}}</p>
              <p>Written by: <i>{{recommendation.author}}</i></p>
              <a :href="recommendation.linkedInLink">See LinkedIn</a>
            </div>
          </div>
        </Slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import FlowRobeLogo from '../../assets/logos/flowrobe.png'
import EasvLogo from '../../assets/logos/easv.png'
import KnowieLogo from '../../assets/logos/knowie.png'
export default {
  name: "Recommendations",
  components: {
    Carousel,
    Slide,
  },
  data: function () {
    return {
      recommendations: [
        {
          id: 1,
          company: "FlowRobe",
          imageLink: FlowRobeLogo,
          position: "Junior Software Developer",
          description: "I was happy having Adam as a part of my startup. " +
              "Although he entered with no experience in the programming language, " +
              "he didn't hesitate to spend his Christmas holidays digging deep to understand it. " +
              "He's hard-working, and has an eagerness to learn.",
          author: "Simon Tykgaard",
          linkedInLink: "https://www.linkedin.com/in/simontykgaard/"
        },
        {
          id: 2,
          company: "EASV",
          imageLink: EasvLogo,
          position: "Computer Science Student",
          description: "Adam is a good and proactive student at Business Academy Southwest. " +
              "He is showing up for class, has a critical approach and works well with his tasks. " +
              "He has a good multicultural understanding and is capable of working in a wide range of " +
              "computer science assignments. Adam has taken a personality test and reflected on the results. " +
              "I can give Adam the best recommendations.",
          author: "Trine Graungaard Hjerrild Thomsen ",
          linkedInLink: "https://www.linkedin.com/in/trineghthomsen/"
        },
        {
          id: 3,
          company: "Knowie",
          imageLink: KnowieLogo,
          position: "Web App Developer",
          description: "One of the many advantages with Adam is his strong eagerness to contribute and make an " +
              "impact on whatever he is working on. This eagerness drives him to better himself and work hard" +
              " to achieve his goals and ultimately deliver value in no time. It also makes him a great asset " +
              "in many ways and very easy to work alongside. I'm happy to recommend Adam and feel free to reach out " +
              "for more information!",
          author: "Kalle Clausen-Bruun",
          linkedInLink: "https://www.linkedin.com/in/kalle-clausen-bruun-28908657/"
        },
      ]
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
.recommendation-box{
  position: relative;
  width: 100%;
  height: 100%;
}

.recommendation-box .title{
  font-family: 'Handlee', cursive;
  font-size: 52px;
  text-align: center;
  padding: 35px 20px 15px;
  letter-spacing: 3px;
  color: #706d6d;
}

.recommendation-box .sub-text{
  text-align: center;
  padding: 35px 20px;
  letter-spacing: 3px;
  color: #706d6d;
  width: 40%;
  margin: auto;
}

.slider-wrapper{
  width: 50%;
  margin: auto;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%,70%);
}

.header {
  display: flex;
  color: #706d6d;
}

.header img{
  width: 50px;
}

.header h2{
  padding: 7px 10px;
}

.header h3{
  padding:10px;
}

.main {
  color: #706d6d;
  width: 75%;
  margin: auto;
  text-align: justify;
}

.main a{
  text-decoration: none;
  color: #706d6d;
  float: right;
}

.main a:hover{
  text-decoration: underline;
}

@media screen and (max-width: 1000px) {
  .slider-wrapper{
    width: 75%;
    margin: auto;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,45%);
  }

  .recommendation-box .sub-text{
    text-align: center;
    padding: 35px 20px;
    letter-spacing: 3px;
    color: #706d6d;
    width: 70%;
    margin: auto;
  }
}

@media screen and (max-width: 690px) {
  .slider-wrapper{
    width: 95%;
    margin: auto;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,45%);
  }

  .recommendation-box .sub-text{
    text-align: center;
    padding: 35px 20px;
    letter-spacing: 3px;
    color: #706d6d;
    width: 90%;
    margin: auto;
  }

}

</style>
