<template>
  <div>
    <h2>Experience</h2>
    <p>
      This is the list of all the positions I've have worked at in
      my professional career. Those positions were part-time, full-time or internships
      that I've tried to gain more experience, skill and to find out if they are something
      I would want to do in the future
    </p>
    <p>Total years of experience: {{countExperience()}}</p>
    <ul>
      <li v-for="experience in experiences.slice().reverse()" :key="experience.id">
        {{experience.position}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Experience",
  data: function () {
    return {
      totalExperience: "" ,
      experiences: [
        {
          id: 1,
          position: "Web Administrator & developer",
          duration: 10
        },
        {
          id: 2,
          position: "UI/UX Developer",
          duration: 3

        },
        {
          id: 3,
          position: "Information Technology",
          duration: 2
        },
        {
          id: 4,
          position: "PHP Web Developer",
          duration: 3
        },
        {
          id: 5,
          position: "Junior Software Developer",
          duration: 1
        },
        {
          id: 6,
          position: "Web Application Developer",
          duration: 4
        },
        {
          id: 7,
          position: "Web Designer & Developer",
          duration: 4
        },
        {
          id: 8,
          position: "Fullstack Net Developer",
          duration: 2
        }
      ]
    }
  },
  methods: {
    countExperience: function (){
      let months = 0;
      this.experiences.forEach(experience => {
        months += experience.duration;
      });

      let years = months / 12;

      return years.toFixed() + " years   " + (months % 12) + " months";
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@1,300&family=Patrick+Hand&display=swap');

h2 {
  font-family: 'Handlee', cursive;
  font-size: 52px;
  text-align: center;
  padding: 35px 20px 15px;
  letter-spacing: 3px;
  color: #706d6d;
}

p{
  color: #706d6d;
  font-family: 'Patrick Hand', cursive;
  font-size: 18px;
  letter-spacing: 1px;
  width: 40%;
  margin: auto;
  padding: 15px 10px 40px;
}

ul{
  color: #706d6d;
  font-family: 'Patrick Hand', cursive;
  text-align: center;
}

ul li {
  list-style-type: none;
  font-size: 18px;
}
</style>
