<template>
<div class="under-construction">
</div>
</template>

<script>
export default {
  name: "UnderConstruction"
}
</script>

<style scoped>
  .under-construction {
    min-height: 90vh;
    background-image: url('./under-construction.jpg');
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
  }
</style>
