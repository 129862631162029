<template>
<div id="intro">
  <p id="intro-welcome">Hello</p>
  <h3 id="intro-name">I'm <span id="name-color">Adam</span> Ondrejkovic</h3>
  <p id="intro-sub">I am an young and ambitious software & web developer, currently in my second year of AP degree. I am a always looking for
  new challenges and possibility to gain experience</p>
  <router-link to="/work"><a href="#" class="home-button">My Work</a></router-link>
  <router-link to="/contact"> <a href="" class="home-button second">Get in touch</a></router-link>
</div>
</template>

<script>
export default {
  name: "Intro"
}
</script>
<style scoped>
#intro {
  padding:20px 5px;
  margin: 5px !important;
  position: relative;
}

#intro-welcome{
  font-family: sans-serif;
  text-align: center;
  font-size: 14px;
  max-width: 100px;
  margin: 0;
  position: relative;
}

#intro-welcome::before{
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background: #000;
  left: 0;
  top: 50%;
  position: absolute;
}

#intro-welcome::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  background: #000;
  right: 0;
  top: 50%;
  position: absolute;
}

#intro-name {
  margin: 0;
}

#name-color {
  color: #6dbdff;
}

.home-button{
  position: absolute;
  right: 10px;
  top: 240px;
  background: rgb(238,174,202);
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
  padding: 5px 7px;
  border-radius: 25px;
  color: white;
  text-decoration: none;
}

.second{
  top: 280px;
}

@media screen and (min-width: 690px) {

  #intro{
    position: absolute;
    left: -10px;
    top: 20px;
    width: 60%;
    background-color:rgba(250%,250%,250%,0.8);
    border-top-right-radius: 25px;
    border-bottom-right-radius:25px;
    max-height: 25vh;
  }

  #intro-name, #intro-welcome, #intro-sub{
    padding-left: 15px;
  }

  #intro-welcome::before{
    left: 5px;
  }

  #intro-welcome::after {
    right: -5px;
  }

  .home-button {
    top: 300px;
    left: 20px;
    width: 80px;
  }

  .second {
    top: 340px;
    width: 105px;
  }
}

@media screen and (min-width: 1000px) {

  #intro{
    position: absolute;
    left: -10px;
    top: 25vh;
    width: 45%;
    background-color:rgba(250%,250%,250%,0.8);
    border-top-right-radius: 25px;
    border-bottom-right-radius:25px;
    max-height: 25vh;
  }

  #intro-name, #intro-welcome, #intro-sub{
    padding-left: 15px;
  }

  #intro-welcome::before{
    left: 5px;
  }

  #intro-welcome::after {
    right: -5px;
  }

  .home-button {
    top: 27vh;
    left: 65%;
    width: 80px;
  }

  .second {
    top: 27vh;
    width: 105px;
    left: 80%;
  }
}



</style>
