import Vue from 'vue'
import VueRouter from "vue-router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Home from "@/pages/Home";
import MyWork from "@/pages/MyWork";
import About from "@/pages/About";
import Contact from "@/pages/Contact";
import App from "@/App";
import References from "@/pages/References";
import MyProjects from "@/pages/MyProjects";
import RealLifeProjects from "@/pages/RealLifeProjects";
import  createWebHashHistory  from 'vue-router';
import 'fullpage.js/vendors/scrolloverflow'
import 'vue-fullpage.js/dist/vue-fullpage.min'
import VueFullPage from 'vue-fullpage.js'

Vue.use(VueFullPage);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.use(VueRouter)



const router = new VueRouter({

    history: createWebHashHistory,
    mode: 'history',
    routes: [
        {path: '/', component:Home },
        {path: '/work', component:MyWork},
        {path: '/about', component:About },
        {path: '/contact', component:Contact },
        {path: '/references', component:References },
        {path: '/myprojects', component:MyProjects},
        {path: '/workprojects', component:RealLifeProjects},
        {path: '/:notFound(.*)', redirect: '/' },

    ],
    linkActiveClass: 'active'
})


new Vue({
    render: h => h(App),
    router
}).$mount('#app');
