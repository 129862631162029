<template>
<div>
  <b-icon-github class="social-button"></b-icon-github>
  <b-icon-linkedin class="social-button"></b-icon-linkedin>
  <b-icon-mailbox class="social-button"></b-icon-mailbox>
  <b-icon-patch-plus class="social-button"></b-icon-patch-plus>
</div>
</template>

<script>
import { BIcon } from 'bootstrap-vue';
import Vue from "vue";
Vue.component('b-icon', BIcon)
export default {
  name: "Social"
}
</script>

<style scoped>
.social-button {
  position: absolute;
  bottom: 30px;
  right: 10px;
  font-size: 20px;
}

.social-button:nth-of-type(2) {
  color:  #0e76a8;
  bottom: 60px;
}

.social-button:nth-of-type(3) {
  color: #BB001B;
  bottom: 90px;
}

.social-button:nth-of-type(4) {
  color: #1AA94C;
  bottom: 120px;
}

@media screen and (min-width: 690px) {
  .social-button {
    position: absolute;
    bottom: 30px;
    left: 20px;
    font-size: 20px;
  }

  .social-button:nth-of-type(2) {
    color:  #0e76a8;
    bottom: 60px;
  }

  .social-button:nth-of-type(3) {
    color: #BB001B;
    bottom: 90px;
  }

  .social-button:nth-of-type(4) {
    color: #1AA94C;
    bottom: 120px;
  }
}

@media screen and (min-width: 1000px) {
  .social-button {
    position: absolute;
    bottom: 30px;
    left: 20px;
    font-size: 20px;
  }

  .social-button:nth-of-type(2) {
    color:  #0e76a8;
    bottom: 30px;
    left: 60px;
  }

  .social-button:nth-of-type(3) {
    color: #BB001B;
    bottom: 30px;
    left: 100px;
  }

  .social-button:nth-of-type(4) {
    color: #1AA94C;
    bottom: 30px;
    left: 140px;
  }
}

</style>
