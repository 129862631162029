<template>
<div>
  References
  <UnderConstruction/>
</div>
</template>

<script>
import UnderConstruction from "@/components/UnderConstruction/UnderConstruction";
export default {
  name: "References",
  components: {UnderConstruction}
}
</script>

<style scoped>

</style>
