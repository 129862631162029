<template>
  <div class="eduction-box">
    <h2 class="title">Education</h2>
    <p class="sub-text">
      Here are listed the schools I've attended on my journey to reach my educational goals.
      Which should provide you with an idea on my educational background as well. For more information about the
      school or the degrees I've studied there you can visit the schools sites.
    </p>
    <carousel :perPage="1" :autoplay="true"  class="slider-box">
      <slide v-for="school in schools" :key="school.id" class="school-box">
        <div><h2>{{school.schoolName}}</h2> <p>{{school.place}}</p></div>
        <h3>{{school.eduction}}</h3>
        <p><i>{{school.duration}}</i></p>
        <p>{{school.description}}</p>
        <a :href="school.linkToSchool" :alt="school.schoolName">See school</a>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
    Carousel,
    Slide,
  },
  name: "Education",
  data: function () {
    return {
      schools: [
        {
          id: 1,
          schoolName: "Koninklijk Technisch Atheneum Zavelenberg",
          place: "Brussels (Belgium)",
          duration: "2015 - 2020",
          eduction: "Information Technology",
          description: "At K.T.A. I've started with economics and foreign languages. Which I've studied for" +
              "3 years after which I choose to follow it up with informatics and economics. I've learned about the" +
              "history of computers, how to set up and maintain and repair different devices. As well web and software development.",
          linkToSchool: "https://www.kta-zavelenberg.com/"
        },
        {
          id: 2,
          schoolName: "Erhvervsakademi SydVest",
          place: "Esbjerg (Denmark)",
          duration: "2020",
          eduction: "AP Degree in Computer Science",
          description: "I've enrolled EASV to further enhance my knowledge and become a professional in my field. " +
              "First year was mainly focused on Java and how to build" +
              "a well structured project. Along that I've been thought in about different  " +
              "development and business approaches. The second year we switched to C# and creating a web applications.",
          linkToSchool: "https://www.easv.dk/da/"
        },
      ]
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@1,300&family=Patrick+Hand&display=swap');

.eduction-box{
  color: #706d6d;
  font-family: 'Patrick Hand', cursive;
}

.title{
  font-family: 'Handlee', cursive;
  font-size: 52px;
  text-align: center;
  padding: 35px 20px 15px;
  letter-spacing: 3px;
  color: #706d6d;
}

.slider-box{
  width: 55%;
  margin: auto;
}

.school-box{
  width: 50%;
  margin: auto;
  padding: 15px;
}

.sub-text{
  text-align: center;
  padding: 25px 20px;
  letter-spacing: 3px;
  width: 40%;
  margin: auto;
}

.school-box div {
  display: flex;
}

.school-box div p{
  padding: 10px 20px;
  font-size: 18px;
}

.school-box p{
  font-size: 18px;
  letter-spacing: 1px;
}

.school-box a {
  float: right;
  color: #706d6d;
  text-decoration: none;
}

.school-box a:hover {
  text-decoration: underline;
}


</style>
