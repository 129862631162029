<template>
<div>
<ProjectChoice/>
</div>
</template>

<script>
import ProjectChoice from "@/components/ProjectChoice";
export default {
  name: "MyWork",
  components: {ProjectChoice}
}
</script>

<style scoped>

</style>
