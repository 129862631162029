<template>
  <div>
    <h2>Certification</h2>
    <p>
      As I like to learn and progress whenever I have time and chance.
      There for I've attended many various courses that helped my in improving
      my self as a developer. And I am always looking for new opportunities to learn
      and improve.
    </p>
    <ul>
      <li v-for="certificate in certificates" :key="certificate.id">
        {{certificate.name}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Certifications",
  data: function () {
    return {
      certificates: [
        {
          id: 1,
          name: "Website design II. - web design, web graphics and CSS advanced"
        },
        {
          id: 2,
          name: "Website design III. - JavaScript, CSS3, AJAX and jQuery"

        },
        {
          id: 3,
          name: "MySQL and SQL language I. - installation, administration, introduction to databases and SQL programming"
        },
        {
          id: 4,
          name: "Adobe Illustrator I. - basic training and introduction to vector graphics"
        },
        {
          id: 5,
          name: "Adobe Illustrator II. - Intermediate drawing and creative"
        },
        {
          id: 6,
          name: "Java I. for beginners"
        },
        {
          id: 7,
          name: " Microsoft SQL Server I. - Introduction to SQL databases"
        },
        {
          id: 8,
          name: "Python I. - for beginners, basics of programming"
        },
        {
          id: 9,
          name: "Java III. - advanced techniques"
        },
      ]
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@1,300&family=Patrick+Hand&display=swap');

h2 {
  font-family: 'Handlee', cursive;
  font-size: 52px;
  text-align: center;
  padding: 35px 20px 15px;
  letter-spacing: 3px;
  color: #706d6d;
}

p{
  color: #706d6d;
  font-family: 'Patrick Hand', cursive;
  font-size: 18px;
  letter-spacing: 1px;
  width: 40%;
  margin: auto;
  padding: 15px 10px 40px;
}

ul{
  color: #706d6d;
  font-family: 'Patrick Hand', cursive;
  text-align: center;
}

ul li {
  list-style-type: none;
  font-size: 18px;
}
</style>
