<template>
  <header id="navBox">
    <span id="navBtn" @click="openNav()">&#9776;</span>
    <div id="myNav" class="overlay" ref="myNav">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
      <div class="overlay-content">
        <router-link to="/"><span @click="closeNav()">Home</span></router-link>
        <router-link to="/about"><span @click="closeNav()">About</span></router-link>
        <router-link to="/work"><span @click="closeNav()">Projects</span></router-link>
        <router-link to="/references"><span @click="closeNav()">References</span></router-link>
        <router-link to="/contact"><span @click="closeNav()">Contact</span></router-link>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {}
  },
  methods: {
    openNav() {
      this.$refs.myNav.style.height = "100%";
    },
    closeNav() {
      this.$refs.myNav.style.height = "0%";
    }
  }
}
</script>

<style scoped>
#navBox{
 position: relative;
  min-height: 50px;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

#navBtn {
  font-size:30px;
  cursor:pointer;
  position: absolute;
  right: 20px;
}

.overlay {
  width: 100%;
  height: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 15%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

</style>
