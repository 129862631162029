<template>
  <div class="about-intro">
    <div>
      <div class="about-description">
        <h2>Who Am I</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci commodi deleniti, deserunt distinctio exercitationem, facilis hic, inventore iste laudantium minus nisi odio optio pariatur praesentium quidem repellendus sint vero voluptatibus.</p>
        <img src="../../assets/meabout.gif" alt="Animation" class="me-animation">
      </div>
    </div>
    <div>
<!--      <video src="">
        <source>
      </video>-->
      <div class="coming-soon">

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutIntro"
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
.about-intro{
  display: flex;
  height: 100%;
  overflow: hidden;
}

.about-intro div{
  width: 50%;
  height: 100%;
  position: relative;
}

.about-description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-10%);
}

.about-description h2{
  font-family: 'Handlee', cursive;
}

.about-description h2, .about-description p {
  z-index: 2;
  position: absolute;
  color: #706d6d;
}

.about-description p {
  top: 5%;
}

.me-animation {
  width: 100%;
  top: -20%;
  right: -20%;
  position: absolute;
  z-index: 1;
}

.coming-soon{
  background: url("../../assets/soon.jpg");
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%,-10%);
  width: 350px!important;
  height:250px!important;
  overflow: hidden;
}
</style>
