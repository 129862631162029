<template>
  <div id="app">
    <Header/>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header";


export default {
  name: 'App',
  components: {
    Header
  },
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.css';

#app {
  width: 100%;
  position: relative;
}
</style>
