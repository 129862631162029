<template>
<ContactBox></ContactBox>
</template>

<script>
import ContactBox from "@/components/Contact/ContactBox";
export default {
  name: "Contact",
  components: {ContactBox}
}
</script>

<style scoped>

</style>
