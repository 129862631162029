<template>
  <div id="home-page">
    <Intro/>
    <Social/>
  </div>
</template>

<script>
import Intro from "@/components/Intro";
import Social from "@/components/Social";
export default {
  name: "Home",
  components: {Social, Intro}
}
</script>

<style scoped>
#home-page {
  position: relative;
  min-height: 90vh;
  background-image: url('home-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
}
</style>
